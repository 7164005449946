.segment {
  &-sidebar {
    min-width: 40rem;

    &-body {
      overflow: hidden;
      position: relative;

      .list-group-item {
        display: grid;
        grid-template-columns: 1fr auto 10%;
      }

      &-item {
        &-controls {
          margin-left: 0.5rem;

        }
      }

    }


  }
  &-multilevel {

    //height: 50vh;


    &-body {
      //overflow: hidden;
      //position: relative;
    }

    &-opener {
      text-align: right;
    }

    &-closer {
      width: 100%;
    }

    &-opener, &-closer {
      cursor: pointer;
      .icon {
        transform: translateY(0.2rem);
      }
    }



    &-leaf {
      overflow: auto;
      height: 100% !important;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--cui-offcanvas-bg);
      transition: 1s transform ease-in-out;

      &.pull {
        transition: 1s transform ease-in-out;
        //transform: translateX(-100%);
      }
      &.push {
        transition: 1s transform ease-in-out;
        //transform: translateX(0%);
      }

      .list-group-item {
        .form-check {
          max-width: 70%;
        }
      }
    }
    &-leaves {
      height: 100% !important;
      overflow: hidden;
      position: relative;
      width: 100%;
      padding-right: 100%;
    }
  }

  &-term__item {
    border: 1px solid var(--cui-medium-emphasis);
    background-color: var(--cui-light);
    padding: 0.2rem;
    cursor: pointer;

    &.op {
      &__or {
        border-color: var(--cui-info-border-subtle);
        background-color: var(--cui-info-bg-subtle);
      }
      &__and {
        border-color: var(--cui-success-border-subtle);
        background-color: var(--cui-success-bg-subtle);
      }
      &__not {
        border-color: var(--cui-danger-border-subtle);
        background-color: var(--cui-danger-bg-subtle);
      }
    }
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
}

.segment-group {
  min-height: 2.4rem;
  background-color: var(--cui-input-bg, var(--cui-body-bg));
  background-clip: padding-box;
  border: var(--cui-border-width) solid var(--cui-input-border-color, var(--cui-border-color));
  border-radius: var(--cui-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.2rem 0.3rem 0.45rem;
  margin-top: 0.5rem;

  &.is-invalid {
    border-color: var(--cui-form-invalid-border-color)
  }

  &>div {
    display: flex;
    //position: relative;
    align-items: center;
  }
  h4 {
    font-size: 1rem;
    margin: 0 0.4rem 0.2rem 0.4rem;
    //position: absolute;
    //top: 0;
    //left: 0;

  }

  .search-tag {

  }
}

.search-segment {
  &-tag{
    &.red {
      border-color: $red-400!important;
      background-color: $red-300!important;
    }
    &.blue {
      border-color: $blue-400!important;
      background-color: $blue-300!important;
    }
    &.green {
      border-color: $green-400!important;
      background-color: $green-300!important;
    }
    &.orange {
      border-color: $orange-400!important;
      background-color: $orange-300!important;
    }
    &.yellow {
      border-color: $yellow-400!important;
      background-color: $yellow-300!important;
    }
    &.purple {
      border-color: $purple-400!important;
      background-color: $purple-300!important;
    }
  }
  &-toggle {
    color: $gray-100;
    &.red {
      border-color: $red-400;
      background-color: $red-300;
    }
    &.blue {
      border-color: $blue-400;
      background-color: $blue-300;
    }
    &.green {
      border-color: $green-400;
      background-color: $green-300;
    }
    &.orange {
      border-color: $orange-400;
      background-color: $orange-300;
    }
    &.yellow {
      border-color: $yellow-400;
      background-color: $yellow-300;
    }
    &.purple {
      border-color: $purple-400!important;
      background-color: $purple-300!important;
    }
  }
  &-container {
    padding: 0.5rem;
    white-space: nowrap;
    display: flex;
    align-items: baseline;

    .dropdown-item {
      cursor: pointer;
    }
    h5 {
      font-size: 1rem;
    }
  }
  &-options {
    &>* {
      margin: 0 0.25rem 0.5rem 0.25rem;
    }
  }
  &-item {
    display: grid;
    grid-template-columns: 1fr 3rem;
    grid-gap: 0.1rem;
    &>*:last-child {
      padding-right: 0.5rem
    }
  }
}
